import React, { useState } from "react";
import {
  Button, Drawer, Grid,
  IconButton,
  Link, List, ListItem,
  makeStyles, Toolbar,
  Typography
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import logo from "../../images/greenexperience.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "inherit"
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  toolbar: {
    // maxHeight: "5em",
    flexWrap: 'wrap',
    // width: "100vw",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  accordion: {
    width: "inherit"
  }
}));

export default function MinNavbar() {
  const classes = useStyles();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <Toolbar className={classes.toolbar} style={{display: "flex"}}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton edge="start" className={classes.logo} color="inherit" aria-label="home"
                      style={{backgroundColor: "transparent"}}>
            <img src={logo} width={"120px"} alt="logo"/>
          </IconButton>
          <Button onClick={() => setMenuIsOpen(!menuIsOpen)}>
            <MenuIcon />
          </Button>
          {/*<Accordion className={classes.accordion} style={{backgroundColor: "transparent"}}>*/}
          {/*  <AccordionSummary*/}
          {/*    expandIcon={<MenuIcon />}*/}
          {/*    aria-controls="panel1c-content"*/}
          {/*    id="panel1c-header"*/}
          {/*  >*/}
          {/*  </AccordionSummary>*/}
          {/*  <AccordionDetails>*/}
          {/*  </AccordionDetails>*/}
          {/*</Accordion>*/}
        </Grid>
      {/*<IconButton>*/}
      {/*  <MenuIcon />*/}
      {/*</IconButton>*/}
      </Toolbar>
      <Drawer anchor="right" open={menuIsOpen} onClose={() => setMenuIsOpen(false)}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              <Typography color="textPrimary">
                Home
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            <Link variant="button" color="textPrimary" href="#services" className={classes.link}>
              <Typography color="textPrimary">
                servicios
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            <Link variant="button" color="textPrimary" href="#residential" className={classes.link}>
              <Typography color="textPrimary">
                Paisajismo residencial
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            <Link variant="button" color="textPrimary" href="#indoor" className={classes.link}>
              <Typography color="textPrimary">
                Paisajismo en oficinas y terrazas
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            <Link variant="button" color="textPrimary" href="#outdoor" className={classes.link}>
              <Typography color="textPrimary">
                Paisajismo y mantenimientos en empresas
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            <Link variant="button" color="textPrimary" href="#about-us" className={classes.link}>
              <Typography color="textPrimary">
                nosotros
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            <Link variant="button" color="textPrimary" href="#our-clients" className={classes.link}>
              <Typography color="textPrimary">
                cartilla de clientes
              </Typography>
            </Link>
          </ListItem>

          <ListItem button>
            {/*<Link variant="button" color="textPrimary" href="#" className={classes.link}>*/}
            {/*  <Typography color="textPrimary">*/}
            {/*    contacto*/}
            {/*  </Typography>*/}
            {/*</Link>*/}
            <Button href="#contact-us" variant="outlined" className={classes.link} style={{color: "whitesmoke", backgroundColor: "#387002"}}>
              Contacto
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}