import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  title: {
    component: "h1",
    variant: "h2",
    color: "black"
  },
  content: {
    lineHeight: "200%",
    color: "",
    component: "p"
  },
});

export default function ServiceCard({ service }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
        <CardMedia
          component="img"
          alt="Jardin"
          // height="160"
          width="100%"
          image={service.link}
          title="Service-01"
        />
        <CardContent>
          {/*<Typography gutterBottom variant="h6" component="h2">*/}
          <Typography className={classes.title} variant="h5">
            {service.title}
          </Typography>
          {/*<Typography variant="p" color="black" component="p">*/}
          <Typography className={classes.content} variant="body2">
            {service.content}
          </Typography>
        </CardContent>
    </Card>
  );
}