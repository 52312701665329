import React from "react";
import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  aboutUs: {
    textAlign: "center",
    padding: "2em 0",
    backgroundColor: "#387002"
  },
  title: {
    padding: ".5em",
    color: "whitesmoke"
  },
  paragraph: {
    fontSize: "1.4em",
    // color: "whitesmoke",
    // padding: ".5em 2em",
    // paddingLeft: "1em",
    // paddingRight: "1em"
  },
  paper: {
    textAlign: "left",
    padding: ".5em 2em",
  }
}));

export default function AboutUs() {
  const classes = useStyles();

  return (
    <section className={classes.aboutUs} id={"about-us"}>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h2">Sobre nosotros</Typography>
      <Grid container justifyContent={"space-around"}>
        <Grid item xs={11} md={8} lg={8}>
          <Paper className={classes.paper} elevation={4}>
            <Typography className={classes.paragraph} paragraph>
              Tal como nuestro nombre expresa creemos que vivir una "experiencia verde" en contacto con la naturaleza es algo fundamental para el día a día tanto en el ambiente laboral como en el hogar de cada uno. Por lo tanto nuestro objetivo es brindar el mejor servicio posible para lograr la satisfacción de nuestros clientes a la hora de presentarnos sus exigencias.
            </Typography>
            <Typography className={classes.paragraph} paragraph>
              Somos una empresa cuyos dueños llevan 3 generaciones dedicándose a la jardinería y al paisajismo. Hemos trabajado tanto en el ámbito público en la refacción y mantenimiento como en la planificación de plazas en la ciudad de Buenos Aires así como en la reserva ecológica de costanera sur. Sin embargo, ya hace varios años nos especializamos en el sector privado como puede verse en nuestra cartera de clientes conformada por empresas de distinta envergadura así como de entidades bancarias.
            </Typography>
            <Typography className={classes.paragraph} paragraph>
              Nuestra filosofía se centra en darle al cliente la mejor solución de la manera más eficaz y al mejor precio posible.
            </Typography>
            <Typography className={classes.paragraph} paragraph>
              Nuestro objetivo es que cuando un cliente nos propone resolver algún problema o satisfacer alguna necesidad, podamos brindarle la mayor cantidad de opciones para que él elija cuál prefiere y así ser absoluto protagonista de su jardín o patio.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </section>
  );
}