import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import ContactButton from "./ContactButton";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  contactInfo: {
    // display: "f"
    display: "grid",
    // textAlign: "center",
    padding: "2em 2em",
    backgroundColor: "white",
  },
  paragraph: {
    padding: ".5em 0",
    fontSize: "1.5em",
    color: "black"
  },
  link: {
    fontSize: "1.5em",
  },
});

export default function ContactInfo({ item }) {
  const classes = useStyles();

  return (
    <Paper elevation={5} className={classes.contactInfo}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: ".5em", width: "100%"}}>
        {/* <InstagramIcon fontSize="large" style={{fontSize: "2em", marginRight: ".5em", textAlign: "center"}} /> */}
        {item.icon}
        <Typography className={classes.paragraph} variant="h2">{item.name}</Typography>
      </div>
      {/*<a href={item.link} target="_blank"><Typography className={classes.link} variant="p">{item.displayText}</Typography></a>*/}
      <div style={{display: "flex", flexDirection: "column", alignContent: "center"}}>
        <ContactButton item={item} />
        {item.link2 && <><br /><ContactButton item={{ link: item.link2, displayText: item.displayText2 }} /></>}
      </div>
    </Paper>
  );
}