import React, {useState} from "react";
import {makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import ImageGrid from "./ImageGrid";
import Modal from "./Modal";
import ImageCarousel from "./ImageCarousel";
import photos from "./photos";
import MyImageList from "./MyImageList";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  gallery: {
    backgroundColor: "white",
  },
  img: {
    minWidth: "100%",
    minHeight: "100%",
    maxWidth: "150%",
    // position: "absolute",
    // top: "0",
    // left: "0",
  },
  title: {
    padding: ".5em",
    marginTop: ".6em",
    textAlign: "center",
  },
}));

export default function Gallery({photosArray}) {
  const classes = useStyles();
  // const theme = useTheme();
  // const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <section className={classes.gallery} id={"gallery"}>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h2">Nuestros trabajos</Typography>
      {/*{isMatch ?*/}
      {/*  // <ImageCarousel photos={photos}/> :*/}
      {/*  // <MyImageList setSelectedImg={setSelectedImg} photos={photos}/> :*/}
        <ImageGrid setSelectedImg={setSelectedImg} photos={photosArray}/>
      {/*}*/}
      {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
    </section>
  );
}