import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clients from "./clients";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    // flexGrow: 1,
    padding: "2em 0",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // maxWidth: "80vw"
    // margin: "auto",
    backgroundColor: "white",
  },
  title: {
    padding: ".5em",
    // color: "whitesmoke"
    textAlign: "center",
  },
  container: {
    padding: "0 10%",
    // margin: "10%",
    justifyContent: "space-around"
  },
  img: {
    height: "130px",
    maxWidth: "400px",
    overflow: "hidden",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    padding: "1em 0"
  },
}));


export default function OurClients() {
  const classes = useStyles();

  return (
    <section className={classes.root} id={"our-clients"}>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h2">Confían en nosotros</Typography>

      <Grid container spacing={0} direction={"row"} className={classes.container}>
        {clients.map(client => (
          <Grid item xs={12} md={6} lg={4} className={classes.item}>
            <img src={client.link} className={classes.img} />
          </Grid>
        ))}
        {/*<Grid item xs={12} md={6} lg={4} className={classes.item}>*/}
        {/*  <img src={"/clients/coca-cola.png"} className={classes.img} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6} lg={4} className={classes.item}>*/}
        {/*  <img src={"/clients/credicoop.png"} className={classes.img} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6} lg={4} className={classes.item}>*/}
        {/*  <img src={"/clients/coca-cola.png"} className={classes.img} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6} lg={4} className={classes.item}>*/}
        {/*  <img src={"/clients/coca-cola.png"} className={classes.img} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6} lg={4} className={classes.item}>*/}
        {/*  <img src={"/clients/coca-cola.png"} className={classes.img} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={6} lg={4} className={classes.item}>*/}
        {/*  <img src={"/clients/coca-cola.png"} className={classes.img} />*/}
        {/*</Grid>*/}
        {/*</Grid>*/}
      </Grid>
    </section>
  );
}