import React from "react";
import { Button, IconButton, Link, makeStyles, Toolbar, Typography } from "@material-ui/core";
import logo from "../../images/greenexperience.png";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  //   maxHeight: "1em",
  //   margin: "2em 4em",
  //
  // },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: "inherit"
  },
  // item: {
  //   padding: "1em 2em",
  //   backgroundColor: "transparent",
  //   "&hover": {
  //     "font-weight": "bolder"
  //   }
  // },
  toolbar: {
    // maxHeight: "5em",
    flexWrap: 'wrap',
    margin: "2em 4em",
    backgroundColor: "transparent",
    height: "5vh"
  },

  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: "#387002"
  },
  // heroContent: {
  //   padding: theme.spacing(8, 0, 6),
  // },
  // cardHeader: {
  //   backgroundColor:
  //     theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  // },
  // cardPricing: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'baseline',
  //   marginBottom: theme.spacing(2),
  // },
  // footer: {
  //   borderTop: `1px solid ${theme.palette.divider}`,
  //   marginTop: theme.spacing(8),
  //   paddingTop: theme.spacing(3),
  //   paddingBottom: theme.spacing(3),
  //   [theme.breakpoints.up('sm')]: {
  //     paddingTop: theme.spacing(6),
  //     paddingBottom: theme.spacing(6),
  //   },
  // },
}));

export default function FullNavbar() {
  const classes = useStyles();

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <IconButton edge="start" className={classes.logo} color="inherit" aria-label="home" href="#home"
                    style={{backgroundColor: "transparent"}}>
          <img src={logo} height={"100px"} alt="logo"/>
        </IconButton>
        <nav style={{display: "flex", alignItems: "center"}}>
          <Link variant="button" color="textPrimary" href="#home" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              Home
            </Typography>
          </Link>

          <Link variant="button" color="textPrimary" href="#services" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              servicios
            </Typography>
          </Link>

          <Link variant="button" color="textPrimary" href="#residential" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              Paisajismo
              <br />
              residencial
            </Typography>
          </Link>

          <Link variant="button" color="textPrimary" href="#indoor" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              Paisajismo en
              <br />
              oficinas y terrazas
            </Typography>
          </Link>

          <Link variant="button" color="textPrimary" href="#outdoor" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              Paisajismo y
              <br />
              mantenimientos en empresas
            </Typography>
          </Link>

          <Link variant="button" color="textPrimary" href="#about-us" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              nosotros
            </Typography>
          </Link>

          <Link variant="button" color="textPrimary" href="#our-clients" className={classes.link}>
            <Typography color="textPrimary" align={"center"}>
              cartilla de clientes
            </Typography>
          </Link>
          {/*<Link variant="button" color="textPrimary" href="#" className={classes.link}>*/}
          {/*  Features*/}
          {/*</Link>*/}
          {/*<Link variant="button" color="textPrimary" href="#" className={classes.link}>*/}
          {/*  Enterprise*/}
          {/*</Link>*/}
          {/*<Link variant="button" color="textPrimary" href="#" className={classes.link}>*/}
          {/*  Support*/}
          {/*</Link>*/}
          <Button variant="outlined" href="#contact-us" className={classes.link} style={{color: "whitesmoke", backgroundColor: "#387002"}}>
            Contacto
          </Button>
        </nav>
      </Toolbar>
    </div>
  )
}