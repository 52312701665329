import React from "react";
import { AppBar, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import "./index.css";
import FullNavbar from "./FullNavbar";
import MinNavbar from "./MinNavbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxHeight: "1em",
    // margin: "2em 4em",
    overflow: "hidden"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: "inherit"
  },
  item: {
    padding: "1em 2em",
    backgroundColor: "transparent",
    "&hover": {
      "font-weight": "bolder"
    }
  },
  toolbar: {
    // maxHeight: "5em",
    flexWrap: 'wrap',
  },


  '@global': {
    ul: {
      margin: 0,
        padding: 0,
        listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100vw",
    margin: 0,
    padding: 0,
    // position: "sticky"
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
  },
}));

// const useStyles = makeStyles((theme) => ({
//   '@global': {
//     ul: {
//       margin: 0,
//       padding: 0,
//       listStyle: 'none',
//     },
//   },
//   appBar: {
//     borderBottom: `1px solid ${theme.palette.divider}`,
//   },
//   toolbar: {
//     flexWrap: 'wrap',
//   },
//   toolbarTitle: {
//     flexGrow: 1,
//   },
//   link: {
//     margin: theme.spacing(1, 1.5),
//   },
//   heroContent: {
//     padding: theme.spacing(8, 0, 6),
//   },
//   cardHeader: {
//     backgroundColor:
//       theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
//   },
//   cardPricing: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'baseline',
//     marginBottom: theme.spacing(2),
//   },
//   footer: {
//     borderTop: `1px solid ${theme.palette.divider}`,
//     marginTop: theme.spacing(8),
//     paddingTop: theme.spacing(3),
//     paddingBottom: theme.spacing(3),
//     [theme.breakpoints.up('sm')]: {
//       paddingTop: theme.spacing(6),
//       paddingBottom: theme.spacing(6),
//     },
//   },
// }));

export default function Navbar() {
  const classes = useStyles();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root} id={"home"}>
      {/*<AppBar position={"static"} color={"transparent"} elevation={0}>*/}
      {/*  <Toolbar className={classes.toolbar}>*/}
      {/*    <IconButton edge="start" className={classes.logo} color="inherit" aria-label="home"*/}
      {/*      style={{backgroundColor: "transparent"}}>*/}
      {/*      <img src={logo} height={"100px"}/>*/}
      {/*    </IconButton>*/}
      {/*    <Typography variant="h6" className={classes.title}>*/}
      {/*      News*/}
      {/*    </Typography>*/}
      {/*    <Button color="inherit" className={"button"}>*/}
      {/*      <Typography variant="h6" className={classes.item}>*/}
      {/*        hola*/}
      {/*      </Typography>*/}
      {/*    </Button>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}
      <AppBar color="default" elevation={0} className={classes.appBar} position="fixed">
          {/*<Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>*/}
          {/*  Company name*/}
          {/*</Typography>*/}
          {isMatch ? <MinNavbar /> : <FullNavbar />}
      </AppBar>
    </div>
  )
}