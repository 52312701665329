import Navbar from "./components/Navbar";
import { CssBaseline } from "@material-ui/core";
import Gallery from "./components/Gallery";
import AboutUs from "./components/AboutUs";
import React from "react";
import './App.css';
import Services from "./components/Services";
import OurClients from "./components/OurClients";
import ContactUs from "./components/ContactUs";
import Hero from "./components/Hero";
import WAIcon from "./components/WAIcon";
import GallerySection from "./components/GallerySection";
import residential from "./components/GallerySection/residential";
import indoor from "./components/GallerySection/indoor";
import outdoor from "./components/GallerySection/outdoor";

function App() {
  return (
    <div>
      <CssBaseline />
      <Navbar />
      <Hero />
      <Services />
      <GallerySection details={residential} />
      <GallerySection details={indoor} />
      <GallerySection details={outdoor} />
      <AboutUs />
      <OurClients />
      <ContactUs />
      <WAIcon />
    </div>
  );
}

export default App;
