export default {
  styles: {
    primaryColor: "#387002",
    secondaryColor: "whitesmoke",
  },
  title: "Paisajismo en oficinas y terrazas",
  tag: "indoor",
  paragraphs: [
    "Numerosos estudios demuestran lo importante que son las plantas para la creación de un ambiente laboral eficiente y agradable.",
    "Nuestro objetivo es crear dicho ambiente con la utilización de macetas de diferentes formas, colores y materiales al igual que plantas de múltiples especies.",
    "Debajo podrás observar algunos de nuestros muchos trabajos en oficinas y terrazas."
  ],
  photos: [
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_095706068_baff5l',
      asset_id: '6c3f009959264275db2e337b1f5f0df5',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786584/green-experience-2/Macetas/IMG_20220708_095706068_baff5l.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_095954032_cpvie4',
      asset_id: 'b4167212ace725dd8a9e880e27cb65f7',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786575/green-experience-2/Macetas/IMG_20220708_095954032_cpvie4.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_101811591_yh6aiq',
      asset_id: 'eea0bafdb73163ad9ef583221ba357c5',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786577/green-experience-2/Macetas/IMG_20220708_101811591_yh6aiq.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_110247008_os2usc',
      asset_id: '59ca139e5ababe78fa3f7a66f78d90f3',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786585/green-experience-2/Macetas/IMG_20220708_110247008_os2usc.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_102115997_lgrsxb',
      asset_id: '7000ca25d488a655ae25d76099a9f2ee',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786581/green-experience-2/Macetas/IMG_20220708_102115997_lgrsxb.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_105007981_dgszrq',
      asset_id: '8a9c85d88be4b6a9c1a88e3bf891cddf',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786583/green-experience-2/Macetas/IMG_20220708_105007981_dgszrq.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/Armado_de_macetas_de_oficina._1_ne4was',
      asset_id: 'dfd1cb712505bafc3158af8f9635a0c3',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786578/green-experience-2/Macetas/Armado_de_macetas_de_oficina._1_ne4was.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/Armado_de_macetas_de_oficina._zkflui',
      asset_id: 'd5862bedad3974da2bb7d1a456b05aa7',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786580/green-experience-2/Macetas/Armado_de_macetas_de_oficina._zkflui.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/Collage-12-08-2023_21.02.22_l2fg99',
      asset_id: '67bccfb119e480b363a12ed44e543cf1',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786564/green-experience-2/Macetas/Collage-12-08-2023_21.02.22_l2fg99.png'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230121_095604112_HDR_plhuec',
      asset_id: 'd8aafc9baa2cf7bf8a8704463feaa955',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786580/green-experience-2/Macetas/IMG_20230121_095604112_HDR_plhuec.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220803_105255306_ggtatn',
      asset_id: '7a4e4f3ca384b85299f972018f47d03f',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786584/green-experience-2/Macetas/IMG_20220803_105255306_ggtatn.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220803_105303737_ccxhpu',
      asset_id: 'b5c0391ac9e57a0b168f80ad9acdd062',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786584/green-experience-2/Macetas/IMG_20220803_105303737_ccxhpu.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220803_123401640_pwjdot',
      asset_id: '2da5c626695c9a543e91322ccc2605ff',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786586/green-experience-2/Macetas/IMG_20220803_123401640_pwjdot.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220803_123432582_gejhgi',
      asset_id: 'eeb1792f0f0df9a6e05f7f43612e1949',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786582/green-experience-2/Macetas/IMG_20220803_123432582_gejhgi.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220803_123440794_fvquhb',
      asset_id: '9267fa15dd21495dbb35c8debffe87a1',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786582/green-experience-2/Macetas/IMG_20220803_123440794_fvquhb.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230117_190843016_vy3ouw',
      asset_id: '2e00b5ca84d601e1a986222e2745a861',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786577/green-experience-2/Macetas/IMG_20230117_190843016_vy3ouw.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230120_120213377_MF_PORTRAIT_pcugkc',
      asset_id: '20744367a1017545775e9b0c521f8127',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786581/green-experience-2/Macetas/IMG_20230120_120213377_MF_PORTRAIT_pcugkc.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230121_095545287_HDR_eievmp',
      asset_id: '90b8c5fcfe79f1f5f501b244e085a537',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786579/green-experience-2/Macetas/IMG_20230121_095545287_HDR_eievmp.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20220708_110336687_ydi9pf',
      asset_id: 'ae8e9a5be692d37947776b74ef63ad1d',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786584/green-experience-2/Macetas/IMG_20220708_110336687_ydi9pf.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230123_122616221_hzawav',
      asset_id: 'c0ff4d40808bb6846186c79bbde2f308',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786586/green-experience-2/Macetas/IMG_20230123_122616221_hzawav.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230123_122721160_kd36o1',
      asset_id: 'f6b298f987ac1bf33ddab3c7b903a047',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786583/green-experience-2/Macetas/IMG_20230123_122721160_kd36o1.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230123_154423777_tcbtzm',
      asset_id: '066d0ffb9eb03adb7ffc92469eb00d3a',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786575/green-experience-2/Macetas/IMG_20230123_154423777_tcbtzm.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230123_160039756_t4c30m',
      asset_id: '481a74a0f757f40cdd08af731eafc9ba',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786558/green-experience-2/Macetas/IMG_20230123_160039756_t4c30m.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230306_171136460_bl6kdt',
      asset_id: '49ce6893fbc7f9829139d1217cd84f1c',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786573/green-experience-2/Macetas/IMG_20230306_171136460_bl6kdt.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230123_160012397_micxuz',
      asset_id: '63946cab63fa2fad4a7561b7a128dc85',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786558/green-experience-2/Macetas/IMG_20230123_160012397_micxuz.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230529_161639032_rwqnjy',
      asset_id: 'b4458844ce012f740acd1fc7aeec1381',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786571/green-experience-2/Macetas/IMG_20230529_161639032_rwqnjy.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_152658581_wgcjtf',
      asset_id: 'bf8b3e3fba30e6af4e1a3def5a9bff53',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786569/green-experience-2/Macetas/IMG_20230728_152658581_wgcjtf.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230810_113240679_m3fmw9',
      asset_id: 'd039d485de75fb0fb62ca58691edcb07',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786567/green-experience-2/Macetas/IMG_20230810_113240679_m3fmw9.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230306_171136460_ydfpyn',
      asset_id: 'cfc7dec5894ce66b892340039228a528',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786567/green-experience-2/Macetas/IMG_20230306_171136460_ydfpyn.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230529_171105555_y6bwql',
      asset_id: '3ec440737ab5c45a0f80acc3514dbf8b',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786563/green-experience-2/Macetas/IMG_20230529_171105555_y6bwql.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230529_171400271_qvyhcr',
      asset_id: 'e12842f6478110bc76ff09ecd3144614',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786561/green-experience-2/Macetas/IMG_20230529_171400271_qvyhcr.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230603_144930199_rdhkmh',
      asset_id: 'b0c6b6df0064873048a7941e6e73211b',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786555/green-experience-2/Macetas/IMG_20230603_144930199_rdhkmh.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230725_124052829_iaxljs',
      asset_id: '3aa7ecb62cf75fc2836ebf117b0cb528',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786571/green-experience-2/Macetas/IMG_20230725_124052829_iaxljs.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_150604731_c39u5j',
      asset_id: '754c1341e3a7a6075bcd685468eed1a7',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786568/green-experience-2/Macetas/IMG_20230728_150604731_c39u5j.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_150647272_tgvbsa',
      asset_id: 'af9820208da469af3c5031d401cb7fe0',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786574/green-experience-2/Macetas/IMG_20230728_150647272_tgvbsa.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_150658516_rbyhbp',
      asset_id: 'd257a4d3eba75219e0d1b661d2291800',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786574/green-experience-2/Macetas/IMG_20230728_150658516_rbyhbp.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_152402936_f2bt8m',
      asset_id: '2bebc7c840afcf4813f1c50ac2567e65',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786571/green-experience-2/Macetas/IMG_20230728_152402936_f2bt8m.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_152407805_cnihqm',
      asset_id: '4ee93ffcc90ae7b715c7a55d051d2340',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786571/green-experience-2/Macetas/IMG_20230728_152407805_cnihqm.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230728_152600612_aqa0da',
      asset_id: 'f7ac517c3abd073186d7f93049cc4e8f',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786567/green-experience-2/Macetas/IMG_20230728_152600612_aqa0da.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230810_101948595_yrch1x',
      asset_id: '0f81a536c681f6d5d6667d987fba78ed',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786572/green-experience-2/Macetas/IMG_20230810_101948595_yrch1x.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230811_153204034_zk596v',
      asset_id: '01c55f81eec683558f1abaa0ed1da739',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786564/green-experience-2/Macetas/IMG_20230811_153204034_zk596v.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230811_153215478_2_oznv0r',
      asset_id: 'fd5dee418da778a0a8f874c7999a3a9c',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786564/green-experience-2/Macetas/IMG_20230811_153215478_2_oznv0r.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230811_153240771_lzc9rv',
      asset_id: '76f5010c255ddb0a2677c5e817168fa9',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786563/green-experience-2/Macetas/IMG_20230811_153240771_lzc9rv.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230912_113621044_voimfm',
      asset_id: '491a46901792e8032a36d82e0dd62b89',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786559/green-experience-2/Macetas/IMG_20230912_113621044_voimfm.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230819_115609897_pu1xdo',
      asset_id: 'f58e71596f39ad2a315763741d4404d2',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786566/green-experience-2/Macetas/IMG_20230819_115609897_pu1xdo.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230912_113631793_ohll14',
      asset_id: 'bef7ac4e577f65116b2561deff71fb0d',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786555/green-experience-2/Macetas/IMG_20230912_113631793_ohll14.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230912_113919206_zccbse',
      asset_id: 'e0c69a9bd4e3408b12bc3cb1b27a8d78',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786565/green-experience-2/Macetas/IMG_20230912_113919206_zccbse.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230929_120602186_mkl4bb',
      asset_id: '61c5324124f3891b3e41b49720508087',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786546/green-experience-2/Macetas/IMG_20230929_120602186_mkl4bb.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230912_113926008_f4hqdh',
      asset_id: 'a8c0142fce0dabf6530a11d65b35d81e',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786557/green-experience-2/Macetas/IMG_20230912_113926008_f4hqdh.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230929_094525452_leru4p',
      asset_id: '9fa5ade4e6972b7fa68567163082c381',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786558/green-experience-2/Macetas/IMG_20230929_094525452_leru4p.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230929_120336484_vhynsq',
      asset_id: '33e4ed3ea6745b9af35d1d30aa83c4e4',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786562/green-experience-2/Macetas/IMG_20230929_120336484_vhynsq.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230929_120618882_ouzyis',
      asset_id: 'd40f68b5e9c728f4160d4a4278f960e0',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786545/green-experience-2/Macetas/IMG_20230929_120618882_ouzyis.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20230929_120624821_uea6ij',
      asset_id: 'ac252c483dddbf3dc55f34211e387856',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786554/green-experience-2/Macetas/IMG_20230929_120624821_uea6ij.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231213_133505628_kzgk54',
      asset_id: 'c29f744b5004e78e2862bd7ade753434',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786549/green-experience-2/Macetas/IMG_20231213_133505628_kzgk54.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240301_133908271_etbdpx',
      asset_id: 'c8baf1a20ebe76d4d7a9b95e4a8634d8',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786535/green-experience-2/Macetas/IMG_20240301_133908271_etbdpx.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240311_113323179_dlicub',
      asset_id: 'edafb416450305c7b7a3327c9d19878c',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786531/green-experience-2/Macetas/IMG_20240311_113323179_dlicub.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231103_110954272_irfqmq',
      asset_id: 'e2a12af717655795f4aca4bdddda3e79',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786551/green-experience-2/Macetas/IMG_20231103_110954272_irfqmq.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231103_111034227_nloid6',
      asset_id: 'bb6c2f88a61f7a3fb469d76a21160ba4',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786549/green-experience-2/Macetas/IMG_20231103_111034227_nloid6.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240111_071109976_mr7kuo',
      asset_id: '16b44929583e3fdcdb2695cd03a81fb2',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786549/green-experience-2/Macetas/IMG_20240111_071109976_mr7kuo.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240301_115504451_nvoogk',
      asset_id: 'b7481f5863cbc927975b412e34faa6f1',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786531/green-experience-2/Macetas/IMG_20240301_115504451_nvoogk.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231213_133707725_awutjs',
      asset_id: '84e01a7a505fcd9056e1a55f7c426498',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786548/green-experience-2/Macetas/IMG_20231213_133707725_awutjs.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231213_133757763_brqphr',
      asset_id: 'e71e7681f024df59a56365d4846adb14',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786555/green-experience-2/Macetas/IMG_20231213_133757763_brqphr.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231213_134458165_turtim',
      asset_id: 'f52d04a4c4383bc5c84ef2a1c1d04bbc',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786545/green-experience-2/Macetas/IMG_20231213_134458165_turtim.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20231213_134757602_uykyiv',
      asset_id: 'b4154e41d3e7d07e14cd3c91496916be',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786551/green-experience-2/Macetas/IMG_20231213_134757602_uykyiv.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240111_084726466_u4yiao',
      asset_id: '498925499250f251d9163b3658e93f35',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786547/green-experience-2/Macetas/IMG_20240111_084726466_u4yiao.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240111_085010044_t36a1z',
      asset_id: '621bd856c98c6e1c83628b1ec0511275',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786554/green-experience-2/Macetas/IMG_20240111_085010044_t36a1z.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240111_085147956_z4tc0j',
      asset_id: 'ec8b3549741e81ef1c49e02d4667350e',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786552/green-experience-2/Macetas/IMG_20240111_085147956_z4tc0j.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240301_115458111_jspayl',
      asset_id: 'e1129332824aaeb63ee8010172b7d8e2',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786533/green-experience-2/Macetas/IMG_20240301_115458111_jspayl.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_140149948_qrpjzv',
      asset_id: '94fec5a1cee268c647d6f30ef475e7f7',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786532/green-experience-2/Macetas/IMG_20240420_140149948_qrpjzv.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173551274_aiacsv',
      asset_id: '5064980653a499b1c94d6a02651e110b',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786530/green-experience-2/Macetas/IMG_20240420_173551274_aiacsv.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173700973_edvqqr',
      asset_id: '2cd995d39ac73a8bf04b5f0d042e36c1',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786534/green-experience-2/Macetas/IMG_20240420_173700973_edvqqr.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173817189_shd2eu',
      asset_id: 'fbf601dda12f6e8d45e6013cbe0814b9',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786542/green-experience-2/Macetas/IMG_20240420_173817189_shd2eu.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173900907_pbnwc7',
      asset_id: '519b2e2d19e0fdd6035ee8138c8f1d59',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786541/green-experience-2/Macetas/IMG_20240420_173900907_pbnwc7.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173959830_avyv76',
      asset_id: '20d89f1be60c6d4e90253c2367dd6f87',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786539/green-experience-2/Macetas/IMG_20240420_173959830_avyv76.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_174019799_uxb7nk',
      asset_id: '24b42d950affc7ae2b80cc26a080d6ff',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786541/green-experience-2/Macetas/IMG_20240420_174019799_uxb7nk.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_174033241_woadsa',
      asset_id: 'fc0a4816fdc9c7508cc7b02b6761633d',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786541/green-experience-2/Macetas/IMG_20240420_174033241_woadsa.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_174052360_qhighy',
      asset_id: '93e85b70a28437ab596a4332e134efef',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786537/green-experience-2/Macetas/IMG_20240420_174052360_qhighy.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_174107092_vf5yll',
      asset_id: '32c629e19210e01e9f8749cefe6a4d0c',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786538/green-experience-2/Macetas/IMG_20240420_174107092_vf5yll.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_174120572_sogltm',
      asset_id: 'dc433d914f696f2b029dc1c739d945c5',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786539/green-experience-2/Macetas/IMG_20240420_174120572_sogltm.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_174135132_sasun6',
      asset_id: '84729984f2b084e380a11d1c2376698f',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786540/green-experience-2/Macetas/IMG_20240420_174135132_sasun6.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173624951_bpdste',
      asset_id: '87224cbd0699a66c5e3245680b763d61',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786534/green-experience-2/Macetas/IMG_20240420_173624951_bpdste.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_173917002_ejxtgo',
      asset_id: 'f27066d80d2f862c099df2b52b51095b',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786542/green-experience-2/Macetas/IMG_20240420_173917002_ejxtgo.jpg'
    },
    {
      public_id: 'green-experience-2/Macetas/IMG_20240420_122210893_ghxjn8',
      asset_id: 'b6c3267206d406376199c042e31892ac',
      folder: 'green-experience-2/Macetas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786531/green-experience-2/Macetas/IMG_20240420_122210893_ghxjn8.jpg'
    }
  ],
}