import React, {useState} from "react";
import {ImageList, ImageListItem, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    position: "relative",
  },
  img: {
    // minWidth: "100%",
    // minHeight: "100%",
    // maxWidth: "150%",
    width: "100%",
    // position: "absolute",
    // top: "0",
    // left: "0",
  },
  text: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    color: "whitesmoke",
    backgroundColor: "gray",
    paddingLeft: "5px",
    fontSize: ".8em",
  }
}));

export default function MyImageList({ setSelectedImg, photos }) {
  const classes = useStyles();

  return (
    <>
      <ImageList cols={2}>
        {photos.map((photo, i) => (
          <ImageListItem className={classes.root} key={i}>
            <img className={classes.img} src={photo.link} alt={photo.description} onClick={() => setSelectedImg(photo)} />
            <div className={classes.text}>
              {photo.description}
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}