import React from "react";
import "./ImageGrid.css";
import {Card, Grid, makeStyles, CardMedia, CardActionArea, useMediaQuery, useTheme} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  img: {
    // minWidth: "100%",
    // minHeight: "100%",
    // maxWidth: "30vw",
    // position: "absolute",
    // top: 0,
    // left: 0,
    // overflow: "hidden",
  },
  gridItem: {
    // height: "100%",
    overflow: "hidden",
    // height: 0,
    // padding: "50% 0",
    // position: "relative",
    // opacity: 0.8,
    // padding: "2vh 2vw"
    // margin: "0 auto"
  },
  grid: {
    // margin: "0px auto",
    alignItems: "center",
    // display: "grid",
    // gridTemplateColumns: "1fr 1fr 1fr",
    // gridGap: "40px",
    // maxWidth: "90vw",
  },
  media: {
    // height: 0,
    // minWidth: "100%",
    minHeight: "100%",
    maxWidth: "150%",
    // paddingTop: '56.25%', // 16:9,
    // marginTop:'30'
  },
  card: {
    // maxWidth: 345,
    // margin: "0 5px"
    minHeight: "100%",
    maxWidth: "150%",
    // position: "relative",
    padding: "0.2vw",
  },
  text: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    color: "whitesmoke",
    backgroundColor: "gray",
    paddingLeft: "5px",
    // padding: "0 auto",
    fontSize: "1.4em",
    // alignContent: "center",
  }
}));

export default function ImageGrid({ setSelectedImg, photos }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("xs"));


  return (
    // <div className={"img-grid"}>
    //   {photos.map((photo, index) => (
    //     <div className={"img-wrap"} key={index}
    //       onClick={() => setSelectedImg(photo.link)}
    //     >
    //       <img src={photo.link} alt="gallery"/>
    //     </div>
    //   ))}
    // </div>
    // TODO agregar lo de animacion y buscar como corno esconder el appbar
    <div className={"grid-div"}>
      <Grid className={classes.grid} container direction={"row"} justifyContent={"center"} spacing={isMatch ? 0 : 2}>
      {photos.map((photo, index) => (
        <Grid item className={classes.gridItem} xs={6} md={6} lg={3} onClick={() => setSelectedImg(photo)} key={index}>
          {/*<div className={classes.img}>*/}
          {/*  <img  src={photo.link} alt="gallery"/>*/}
          {/*</div>*/}
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image={photo.url.replace(/\/image\/upload\//, "/image/upload/q_auto:low/")}
                title={photo.description}
              />
              <div className={"text"}>
                {photo.description}
              </div>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
    </div>
  )
};