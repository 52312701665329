import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ServiceCard from "./ServiceCard";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    padding: "2em 10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // maxWidth: "80vw"
    // margin: "0 10%",
    backgroundColor: "#387002",
  },
  paper: {
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    padding: "1em 0",
  },
  title: {
    padding: ".5em",
    color: "whitesmoke",
    textAlign: "center",
  },
}));

const services = [
  {
    title: "Mantenimiento",
    content: <>
      • Desmalezado y limpieza de terrenos.
      <br />
      • Corte y mantenimiento de césped.
      <br />
      • Poda de árboles, arbustos y enredaderas de baja altura.
      <br />
      • Aplicación de mata yuyos.
      <br />
      • Control de maleza.
      <br />
      • Sanidad vegetal (control y prevención de plagas y pestes).
    </>,
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1634607016/green-experience/services/Mantenimiento_de_fabricas_eternit_S.A_kiwyky.jpg"
  },
  {
    title: "Diseño y creación",
    content: <>
      • Diseño de jardines tanto pequeños como medianos y grandes.
      <br />
      • Armado de macetas para casas y oficinas.
      <br />
      • Realizamos presupuestos rápido y detallado.
      <br />
      • Plantación de césped y plantas de todas las especies.
    </>,
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1634607015/green-experience/services/Dise%C3%B1o_y_mantenimiento_de_terrazas_edificio_central_del_banco_Santander_Rio_ffm5mk.jpg"
  },
  {
    title: "Bienes de uso",
    content: <>
      • Contamos con las herramientas y maquinaria más adecuadas para cada trabajo.
      <br />
      • Además, contamos con nuestra propia flota de vehículos.
      <br />
      • Todos los trabajadores cuentan con ART y los elementos de seguridad adecuados.
    </>,
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1634607014/green-experience/services/Corte_de_cespedo_con_todo_el_equipamiento_de_seguridad_necesario__l6wpco.jpg"
  },
];

export default function Services() {
  const classes = useStyles();

  return (
    <section className={classes.root} id={"services"}>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h2">Servicios</Typography>

      <Grid container direction={"row"} justifyContent={"space-around"}>
        {services.map((service, index) => (
          <Grid item xs={12} md={6} lg={4} className={classes.paper} key={index}>
            <ServiceCard service={service} />
          </Grid>
        ))}
      </Grid>
    </section>
  );
}