import React from "react";
import {
  makeStyles,
  Typography,
  responsiveFontSizes,
  MuiThemeProvider,
  createTheme,
  useMediaQuery, useTheme
} from "@material-ui/core";
import Background from "./Background";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  heroSection: {
    // background: "url('services/service-01.jpg') center center/cover no-repeat",
    height: "100vh",
    width: "100%",
    // display: "flex",
    textAlign: "center",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
    // objectFit: "contain",
    // position: "relative",
    // textOverflow: "unset",
  },
  title: {
    // width: "100%",
    // color: "whitesmoke",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // margin: "0 auto",
    // fontSize: "3.5rem",
    // textAlign: "center",
    width: "clamp(5ch, 100%, 45ch)",
    fontFamily: "'Noto Serif', serif",
  },
  textArea: {
    // height: useMediaQuery(theme.breakpoints.down("md")) ? "45%" : "30%",
    // height: "30%",
    backgroundColor: "white",
    color: "#387002",
    display: "grid",
    placeItems: "center",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignContent: "center",
    height: "100%",
    // padding: "auto"
  },
}));

let customTheme = createTheme();
customTheme = responsiveFontSizes(customTheme);

export default function Hero() {
  const classes = useStyles();
  const theme = useTheme();
  // const isMatch = useMediaQuery(customTheme.breakpoints.down("md"));
  // customTheme.asd = isMatch ? "green" : "black"

  return (
    <section className={classes.heroSection} id="hero">
      <MuiThemeProvider theme={customTheme}>
        {/*<div className={classes.toolbar} />*/}
        <Background />
        <div className={classes.textArea} style={{height: useMediaQuery(theme.breakpoints.down("md")) ? "45%" : "30%",}}>
          <Typography className={classes.title} variant="h3">
            Green Experience es una empresa con más de 25 años de experiencia dedicada al diseño y mantenimiento de espacios verdes exteriores e interiores
            {/*<br />25 años de experiencia dedicada al mantenimiento*/}
            {/*<br />de espacios verdes exteriores e interiores*/}
          </Typography>
        </div>
      </MuiThemeProvider>
    </section>
  )
}