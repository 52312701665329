const clients = [
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1722796436/green-experience/logos/large_Etex-logo_yjivpw.png"
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1722796437/green-experience/logos/Adidas_Logo.svg_hhwke0.png"
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1722796437/green-experience/logos/kavak4824_s6qsuq.jpg"
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1722796438/green-experience/logos/exologistica_logo_agv91q.jpg",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1710294791/green-experience/logos/logo_salesforce_hgae1e.png"
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1710294613/green-experience/logos/logo_facility_service_j6asfl.png"
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1636934056/green-experience/logos/logo_Coca-Cola_eb2twf.png",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1636935224/green-experience/logos/logo_banco_galicia_pswvoz.png",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_sim_wen5q8.jpg",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_icbc_mgewdw.jpg",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_thyssen_wxosxw.png",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_santander_jiygef.jpg",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_cencosud_ogsfbv.png",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_takeda_gjggeu.png",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1635210017/green-experience/logos/logo_gestam_khv1xz.png",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1647393356/green-experience/logos/credicoop_p0l4ww.jpg",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1664729483/green-experience/logos/erpla_mspysp.jpg",
  },
  {
    link: "https://res.cloudinary.com/dt96crg9v/image/upload/v1677021990/green-experience/logos/pedidos-ya_nx7vtu.png"
  },
];

export default clients;