import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1, 1.5),
    backgroundColor: "#387002",
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "#387002",
    }
  },
  paragraph: {
    padding: ".5em 0",
    fontSize: "1.2em",
  },
}));

export default function ContactButton({ item }) {
  const classes = useStyles();

  return (
    <Button variant="outlined" href={item.link} className={classes.button} style={{  }}>
      <Typography className={classes.paragraph} variant="body2">{item.displayText}</Typography>
    </Button>
  )
}