import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ContactInfo from "./ContactInfo";
import {Instagram, MailOutline as EmailIcon, WhatsApp} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    padding: "2em 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // maxWidth: "80vw"
    margin: "0 10%"
  },
  contactUs: {
    textAlign: "center",
    padding: "2em 0",
    // backgroundColor: "#387002",
    backgroundColor: "white"
  },
  paper: {
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    padding: "1em 0",
  },
  title: {
    padding: ".5em",
    // color: "whitesmoke",
    textAlign: "center",
  },
  paragraph: {
    fontSize: "1.8em",
    // color: "whitesmoke",
    textAlign: "center",
  },
}));

const items = [
  {
    // icon: <CallIcon fontSize="large" style={{fontSize: "2em", marginRight: ".5em", textAlign: "center"}} />,
    icon: <WhatsApp fontSize="large" style={{fontSize: "2em", marginRight: ".5em", textAlign: "center"}} />,
    name: "WHATSAPP",
    displayText: "Celular: 11 6058-2285",
    link: "https://wa.me/5491160582285?text=%C2%A1Hola%21+Como+estas%3F",
    displayText2: "Celular: 11 3117-2285",
    link2: "https://wa.me/5491131172285?text=%C2%A1Hola%21+Como+estas%3F"
  },
  {
    icon: <Instagram fontSize="large" style={{fontSize: "2em", marginRight: ".5em", textAlign: "center"}} />,
    name: "INSTAGRAM",
    displayText: "@greenexperience.ar",
    link: "https://www.instagram.com/greenexperience.ar"
  },
  {
    icon: <EmailIcon fontSize="large" style={{fontSize: "2em", marginRight: ".5em", textAlign: "center"}} />,
    name: "EMAIL",
    displayText: "greenexperience@yahoo.com",
    link: "mailto:greenexperience@yahoo.com"
  },
  // {
  //   icon: <InstagramIcon fontSize="large" style={{fontSize: "2em", marginRight: ".5em", textAlign: "center"}} />,
  //   name: "INSTAGRAM",
  //   displayText: "@greenexperience",
  //   link: "https://www.instagram.com/greenexperience/"
  // },
];

export default function Gallery() {
  const classes = useStyles();

  return (
    <section id={"contact-us"} className={classes.contactUs}>
      <Typography className={classes.title} variant="h2">Contacto</Typography>
      <Typography className={classes.paragraph} variant={"body2"} >
        Por sugerencias o consultas adicionales puede comunicarse a través de:
      </Typography>
      <Grid container direction={"row"} justifyContent={"center"}>
        <Grid item xs={12} md={6} lg={4} className={classes.paper}>
          <ContactInfo item={items[0]}/>
        </Grid>

        <Grid item xs={12} md={6} lg={4} className={classes.paper}>
          <ContactInfo item={items[1]}/>
        </Grid>
        
        <Grid item xs={12} md={6} lg={4} className={classes.paper}>
          <ContactInfo item={items[2]}/>
        </Grid>
        
        {/*<Grid item xs={12} md={6} lg={4} className={classes.paper}>*/}
        {/*  <ContactInfo item={items[2]}/>*/}
        {/*</Grid>*/}
      </Grid>
    </section>
  );
}