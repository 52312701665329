import React from "react";
import {Link, makeStyles, Tooltip, useMediaQuery, useTheme} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  row: {
    // height: useMediaQuery(theme.breakpoints.down("md")) ? "55%" : "70%",
    // height: "70%",
    display: "flex",
    // backgroundColor: theme.asd,
    // padding: "0 1em",
    // alignContent: "stretch",
    // justifyContent: "stretch",
    // objectFit: "contain",
    // boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.5)",
  },
  column: {
    flex: "33.33%",
    // padding: "5px",
    // objectFit: "cover",
    // boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.5)",
    overflow: "hidden",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    // opacity: 0.5,
    // boxShadow: "initial",
    transform: "scale(1,1)",
    transition: "1s",
    '&:hover': {
      transform: "scale(1.1, 1.1)",
    },
  },
}));
export default function Background() {
  const classes = useStyles();
  const theme = useTheme();
  // const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.row} style={{height: useMediaQuery(theme.breakpoints.down("md")) ? "55%" : "70%",}}>
      <div className={classes.column} >
        <Link href="#residential">
          <img className={classes.img}
               src="https://res.cloudinary.com/dt96crg9v/image/upload/v1722486347/green-experience/hero/patio_qokqbj.jpg"
               alt="patio" />
        </Link>
      </div>
      <div className={classes.column}>
        <Link href="#indoor">
          {/*<img className={classes.img} src="https://res.cloudinary.com/dt96crg9v/image/upload/v1634607016/green-experience/hero/Mantenimiento_de_otra_de_las_fabricas_para_las_que_trabajamos_o7jhpg.jpg" alt="Forest" />*/}
          <img className={classes.img} src="https://res.cloudinary.com/dt96crg9v/image/upload/v1722486347/green-experience/hero/maceta_qudjws.jpg" alt="macetas" />
        </Link>
      </div>
      <div className={classes.column}>
        <Link href="#outdoor">
          {/*<img className={classes.img} src="https://res.cloudinary.com/dt96crg9v/image/upload/v1635109949/green-experience/hero/patio_q063jn.jpg" alt="Mountains" />*/}
          <img className={classes.img} src="https://res.cloudinary.com/dt96crg9v/image/upload/v1634607014/green-experience/services/Corte_de_cespedo_con_todo_el_equipamiento_de_seguridad_necesario__l6wpco.jpg" alt="Mountains" />
        </Link>
      </div>
    </div>
  )
}