export default {
  styles: {
    primaryColor: "whitesmoke",
    secondaryColor: "#387002",
  },
  title: "Paisajismo residencial",
  tag: "residential",
  paragraphs: [
    "Nuestro objetivo es crear un espacio verde para que vos y tu familia puedan disfrutar de un jardin agradable obedeciendo tus  gustos y necesidades. Debajo podrás observar algunos de nuestros tantos trabajos en esta área."
  ],
  photos: [
    {
      public_id: 'green-experience-2/Paisajismo en casas/Collage-12-08-2023_20.57.02_knlpux',
      asset_id: 'ee1457abb2f0b87304c7f67b3de02190',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480417/green-experience-2/Paisajismo%20en%20casas/Collage-12-08-2023_20.57.02_knlpux.png'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/Collage-12-08-2023_21.14.38_ugshbg',
      asset_id: 'd30b097cc0210d9292ef53bbca9976da',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480416/green-experience-2/Paisajismo%20en%20casas/Collage-12-08-2023_21.14.38_ugshbg.png'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/Collage-13-07-2023_17.39.51_ytz8tg',
      asset_id: '106d9c92c4ffebab0824952b17e3a2bb',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480410/green-experience-2/Paisajismo%20en%20casas/Collage-13-07-2023_17.39.51_ytz8tg.png'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/Collage-22-07-2023_20.01.08_likywk',
      asset_id: '3832c1481ba3ed93805236399f72d162',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480420/green-experience-2/Paisajismo%20en%20casas/Collage-22-07-2023_20.01.08_likywk.png'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230513_104433722_w6qcy8',
      asset_id: '38edcb4c9fed89a1482293d9e50e4813',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480417/green-experience-2/Paisajismo%20en%20casas/IMG_20230513_104433722_w6qcy8.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230513_122259003_x27ltr',
      asset_id: '4079837bc1f6e066dd20f4c7a949df89',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480414/green-experience-2/Paisajismo%20en%20casas/IMG_20230513_122259003_x27ltr.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230629_162216578_kqmrxd',
      asset_id: 'f31b3718a2d128bd6a89807be663bcd3',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480421/green-experience-2/Paisajismo%20en%20casas/IMG_20230629_162216578_kqmrxd.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230629_162235968_bsv16r',
      asset_id: 'b885768fb84f190963072140481aed6b',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480413/green-experience-2/Paisajismo%20en%20casas/IMG_20230629_162235968_bsv16r.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230629_131909317_qjchwp',
      asset_id: '26394f209c614f23cff4a38066493b84',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480420/green-experience-2/Paisajismo%20en%20casas/IMG_20230629_131909317_qjchwp.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230719_104705591_egj15x',
      asset_id: '9889366fad93c9bcd17c4e54571df8d5',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480414/green-experience-2/Paisajismo%20en%20casas/IMG_20230719_104705591_egj15x.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231020_150450641_pymxf8',
      asset_id: '13d740429b574c507a674f9c4956099e',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480435/green-experience-2/Paisajismo%20en%20casas/IMG_20231020_150450641_pymxf8.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231025_145152370_hq0pzh',
      asset_id: 'fec9d1a7aec4e1a196d70e1a0d219f0e',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480434/green-experience-2/Paisajismo%20en%20casas/IMG_20231025_145152370_hq0pzh.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230629_162256620_u99xsn',
      asset_id: '269bd9791d2aaa102f2c55ffbd938302',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480412/green-experience-2/Paisajismo%20en%20casas/IMG_20230629_162256620_u99xsn.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230811_153235109_jxitng',
      asset_id: '3f1ec7a3a4f3a59308c296f1efe1a0de',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480410/green-experience-2/Paisajismo%20en%20casas/IMG_20230811_153235109_jxitng.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230927_115516760_ruq2t8',
      asset_id: '4253e2a12c098fe1c1f929102042c934',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480414/green-experience-2/Paisajismo%20en%20casas/IMG_20230927_115516760_ruq2t8.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230928_165739535_tpe7b2',
      asset_id: '2aaec83043fc9f9909b07b5e2a364bf8',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480437/green-experience-2/Paisajismo%20en%20casas/IMG_20230928_165739535_tpe7b2.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230928_174025250_rsbop1',
      asset_id: 'ffc5d84d5f0cbd5ef1cf5f1976e25397',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480431/green-experience-2/Paisajismo%20en%20casas/IMG_20230928_174025250_rsbop1.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230928_174031600_i4upll',
      asset_id: '2dfda98e3029767ef8c0341f6e7ecba3',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480431/green-experience-2/Paisajismo%20en%20casas/IMG_20230928_174031600_i4upll.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20230928_174046171_rrvuiu',
      asset_id: '43de5fed4c514f0a33f78b0f9760eb69',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480435/green-experience-2/Paisajismo%20en%20casas/IMG_20230928_174046171_rrvuiu.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231020_150417872_b2ufyz',
      asset_id: '1572abd04ac7110db2a040594bb5eaed',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480437/green-experience-2/Paisajismo%20en%20casas/IMG_20231020_150417872_b2ufyz.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231209_121126580_shxhkf',
      asset_id: '2d091fe3a22c4ab3bc6f91df45f7a40a',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480429/green-experience-2/Paisajismo%20en%20casas/IMG_20231209_121126580_shxhkf.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231209_133201971_gg2fbo',
      asset_id: '30b74f0dde71ccbad2a3fc64a89c1343',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480457/green-experience-2/Paisajismo%20en%20casas/IMG_20231209_133201971_gg2fbo.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231212_123630752_xtylyn',
      asset_id: '2323ea83e94b3324e1448eeac69ad12e',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480450/green-experience-2/Paisajismo%20en%20casas/IMG_20231212_123630752_xtylyn.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231212_124404673_nporwc',
      asset_id: '19aa5dfd4563b00a5a624b939303839b',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480466/green-experience-2/Paisajismo%20en%20casas/IMG_20231212_124404673_nporwc.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231020_150417872_yktyha',
      asset_id: 'dc677ecf59c64aaafa60e503a8f4cb4e',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480422/green-experience-2/Paisajismo%20en%20casas/IMG_20231020_150417872_yktyha.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231020_150503030_v7b1pd',
      asset_id: '34469f5cebdde9f1a8dee2b1afbb6a12',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480436/green-experience-2/Paisajismo%20en%20casas/IMG_20231020_150503030_v7b1pd.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231025_145028081_tdqy8f',
      asset_id: 'c4ee18b2e1d516ef4a12ce39a423acf3',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480431/green-experience-2/Paisajismo%20en%20casas/IMG_20231025_145028081_tdqy8f.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231124_103857422_kmkua5',
      asset_id: '106fcb1cbb260695ea6a3b205830b2e0',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480445/green-experience-2/Paisajismo%20en%20casas/IMG_20231124_103857422_kmkua5.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231124_113725951_m4ryws',
      asset_id: 'e9dde08a9cd4becd2314210a4ee1126c',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480425/green-experience-2/Paisajismo%20en%20casas/IMG_20231124_113725951_m4ryws.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231209_121112536_vvkkts',
      asset_id: '80b4fbf8f3091d66d2d1e3e126a9b881',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480442/green-experience-2/Paisajismo%20en%20casas/IMG_20231209_121112536_vvkkts.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231209_123045688_g4dldc',
      asset_id: '073ef2d15c1d2a028cd20e33090becc5',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480429/green-experience-2/Paisajismo%20en%20casas/IMG_20231209_123045688_g4dldc.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231212_123605705_cjjumu',
      asset_id: 'c19f52de289789910dc8d6c868286c6d',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480464/green-experience-2/Paisajismo%20en%20casas/IMG_20231212_123605705_cjjumu.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231212_131447441_irshhv',
      asset_id: '36beb3d1ef848977fde32dbc141300ad',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480456/green-experience-2/Paisajismo%20en%20casas/IMG_20231212_131447441_irshhv.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231222_153300486_k90tpa',
      asset_id: '54c97712f667ba3ddec6974ef943949d',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480454/green-experience-2/Paisajismo%20en%20casas/IMG_20231222_153300486_k90tpa.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240216_140104602_spdd71',
      asset_id: '5af0cc0352355a8356561cf87f8795e6',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480472/green-experience-2/Paisajismo%20en%20casas/IMG_20240216_140104602_spdd71.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240301_115458111_y4pihh',
      asset_id: 'bd4db72362747005d5939faa0cf82a9d',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480475/green-experience-2/Paisajismo%20en%20casas/IMG_20240301_115458111_y4pihh.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231212_131929969_aeuonm',
      asset_id: 'e6d0ed39bfc7014c10417c179fa3d183',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480453/green-experience-2/Paisajismo%20en%20casas/IMG_20231212_131929969_aeuonm.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231212_131955582_ubwc5s',
      asset_id: '20defcea8cf238773d1b430af358356d',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480457/green-experience-2/Paisajismo%20en%20casas/IMG_20231212_131955582_ubwc5s.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231222_153505400_ynuald',
      asset_id: '057bf742f8904989e4c5bf37a6f91d0a',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480461/green-experience-2/Paisajismo%20en%20casas/IMG_20231222_153505400_ynuald.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231222_153514084_xcejcu',
      asset_id: '48a9d11a40d9d45fc490a1a93afe1c77',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480453/green-experience-2/Paisajismo%20en%20casas/IMG_20231222_153514084_xcejcu.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231222_153526578_bf4uub',
      asset_id: '1a7453cef7b76292d18ffa6b1c9ae012',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480458/green-experience-2/Paisajismo%20en%20casas/IMG_20231222_153526578_bf4uub.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20231226_112911919_uctdnz',
      asset_id: 'c342fa719b89445b34a2feae69dbe991',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480465/green-experience-2/Paisajismo%20en%20casas/IMG_20231226_112911919_uctdnz.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240111_110744605_yxwpzf',
      asset_id: '02b460627a04f7270e4d73e57af7c414',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480462/green-experience-2/Paisajismo%20en%20casas/IMG_20240111_110744605_yxwpzf.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240216_140115699_l2r47c',
      asset_id: 'f6af7873c10ff654d64085689b9d455e',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480479/green-experience-2/Paisajismo%20en%20casas/IMG_20240216_140115699_l2r47c.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240301_133920305_jpfbz4',
      asset_id: 'ddab17e77e53b9597be379aca8fe6f58',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480469/green-experience-2/Paisajismo%20en%20casas/IMG_20240301_133920305_jpfbz4.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240301_133946522_gvvhvr',
      asset_id: 'ad09c7b5e2e12f65d9cf652174f2fa4e',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480472/green-experience-2/Paisajismo%20en%20casas/IMG_20240301_133946522_gvvhvr.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240305_095953497_pzjbtm',
      asset_id: '27bfec192bed8cea3de55e3802836986',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480478/green-experience-2/Paisajismo%20en%20casas/IMG_20240305_095953497_pzjbtm.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240305_113715233_wnfe72',
      asset_id: 'd7386e8db6b7c687c7b831ab81fedd50',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480476/green-experience-2/Paisajismo%20en%20casas/IMG_20240305_113715233_wnfe72.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240216_140121244_blwrt7',
      asset_id: '61fbba6b08e4f4eb03064f6c6e7f1d97',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480481/green-experience-2/Paisajismo%20en%20casas/IMG_20240216_140121244_blwrt7.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240216_140142989_hkzyzp',
      asset_id: '8390a0fb94cf342f65758800b4e41095',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480469/green-experience-2/Paisajismo%20en%20casas/IMG_20240216_140142989_hkzyzp.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240301_115504451_o4alrz',
      asset_id: '6997fc476d1700103fc27025a2b77fd9',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480472/green-experience-2/Paisajismo%20en%20casas/IMG_20240301_115504451_o4alrz.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240301_133930723_idzlye',
      asset_id: '4b1c779165e0452d437842decbc88782',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480467/green-experience-2/Paisajismo%20en%20casas/IMG_20240301_133930723_idzlye.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20230621-WA0026_mzuozh',
      asset_id: 'fcac4cdb782557b8450fbd3fcec2ffba',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480417/green-experience-2/Paisajismo%20en%20casas/IMG-20230621-WA0026_mzuozh.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20230719-WA0008_yqioow',
      asset_id: '1c73000307c28d01033fcbe6858634f3',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480413/green-experience-2/Paisajismo%20en%20casas/IMG-20230719-WA0008_yqioow.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20231010-WA0043_hxa7vg',
      asset_id: '2a038c1823bf3f2cf71faf45dfd01ca1',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480427/green-experience-2/Paisajismo%20en%20casas/IMG-20231010-WA0043_hxa7vg.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20231209-WA0014_vrpj2m',
      asset_id: 'cc26a920a1859acd8c36d2beb1ba23db',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480434/green-experience-2/Paisajismo%20en%20casas/IMG-20231209-WA0014_vrpj2m.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG_20240305_113722726_faa9ve',
      asset_id: '358b87bd97ec2b7b1d415faa19badcac',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480478/green-experience-2/Paisajismo%20en%20casas/IMG_20240305_113722726_faa9ve.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20231003-WA0027_wqq7xs',
      asset_id: '64c0a6a58991b2db859e1a095a4da218',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480423/green-experience-2/Paisajismo%20en%20casas/IMG-20231003-WA0027_wqq7xs.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20231019-WA0016_tlgkit',
      asset_id: '600127e12fb976ea3d8add94efa0f23c',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480438/green-experience-2/Paisajismo%20en%20casas/IMG-20231019-WA0016_tlgkit.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20231019-WA0018_vpmoeb',
      asset_id: 'd42cca0abbb0d4878a76bb6945f5360c',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480427/green-experience-2/Paisajismo%20en%20casas/IMG-20231019-WA0018_vpmoeb.jpg'
    },
    {
      public_id: 'green-experience-2/Paisajismo en casas/IMG-20231211-WA0005_ldd9zz',
      asset_id: 'f3e4ef8eeb515f5fcc5ae9b6ec0da8da',
      folder: 'green-experience-2/Paisajismo en casas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722480463/green-experience-2/Paisajismo%20en%20casas/IMG-20231211-WA0005_ldd9zz.jpg'
    }
  ],
}