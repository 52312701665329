export default {
  styles: {
    primaryColor: "whitesmoke",
    secondaryColor: "#387002",
  },
  title: "Paisajismo y mantenimientos en empresas",
  tag: "outdoor",
  paragraphs: [
    "Sabemos de lo importante que son los espacios verdes para las empresas en sus diferentes plantas y locaciones.",
    "Por tal motivo brindamos tanto la creatividad para embellecer dichos espacios como el compromiso y los recursos para mantenerlos siempre prolijos.",
    "Debajo podrá observar algunos de nuestros trabajos en diferentes empresas."
  ],
  photos: [
    {
      public_id: 'green-experience-2/Empresas/20210825_155115_riaxm7',
      asset_id: '17a78e5fa85a37e957d7ab65cd885d7c',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786939/green-experience-2/Empresas/20210825_155115_riaxm7.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20210812_134030_j7gvjd',
      asset_id: '7bbef07ab188fb02f0ed1771fd7cf15b',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786942/green-experience-2/Empresas/20210812_134030_j7gvjd.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20210812_134214_fpp3gg',
      asset_id: '637d8b9e0af90ef0db92ee29c92f8764',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786946/green-experience-2/Empresas/20210812_134214_fpp3gg.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20210813_111240_q9nbxt',
      asset_id: '2239ecb311bad3612a1bed90b832fd05',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786945/green-experience-2/Empresas/20210813_111240_q9nbxt.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20200204_111655_n0qdkk',
      asset_id: '0663edf58171060cce831526d863d2ff',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786938/green-experience-2/Empresas/20200204_111655_n0qdkk.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20210804_144830_scyplv',
      asset_id: '3f4604f7c53b961befae9fa6bda4d332',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786942/green-experience-2/Empresas/20210804_144830_scyplv.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220815_135643219_jrbmvb',
      asset_id: '6de7a5e952743f78d1ad839eac6ed592',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786922/green-experience-2/Empresas/IMG_20220815_135643219_jrbmvb.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20221003_134050852_d1dgbx',
      asset_id: '5d1782a40fc3056a31d09a5aa11e6d8a',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786924/green-experience-2/Empresas/IMG_20221003_134050852_d1dgbx.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20210813_111307_z7kuno',
      asset_id: 'cdf7be5d022225fab06e6c80bf360d56',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786947/green-experience-2/Empresas/20210813_111307_z7kuno.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20210813_140009_hiyjqq',
      asset_id: '5648bff84b99e40ed3f18a7cba06b15f',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786937/green-experience-2/Empresas/20210813_140009_hiyjqq.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/20211101_104156_czxkje',
      asset_id: 'f1542deafa056ace22c21cd5e3ba20a1',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786944/green-experience-2/Empresas/20211101_104156_czxkje.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220407_114404501_rmtcbv',
      asset_id: '58d7cbabe8992ad026020d753852e5c4',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786943/green-experience-2/Empresas/IMG_20220407_114404501_rmtcbv.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220411_135300571_jwx2eo',
      asset_id: '4bb16e48edcf0cacf1ba617953c103c5',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786950/green-experience-2/Empresas/IMG_20220411_135300571_jwx2eo.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220419_120607319_hxy5ln',
      asset_id: 'd43b48314bbaf8f642659534f54f1157',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786942/green-experience-2/Empresas/IMG_20220419_120607319_hxy5ln.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220419_122844205_csbjru',
      asset_id: '145a628856a536bc9c134013b0b4c4a7',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786941/green-experience-2/Empresas/IMG_20220419_122844205_csbjru.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220524_121524977_r2inzz',
      asset_id: 'db6bad0da6cf17849f6ab72962e0fc24',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786950/green-experience-2/Empresas/IMG_20220524_121524977_r2inzz.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220524_121608124_j4z9c1',
      asset_id: '72fdcd0e2a4e8ba25ef7a2efe8c83381',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786947/green-experience-2/Empresas/IMG_20220524_121608124_j4z9c1.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220607_114323273_dyzkjn',
      asset_id: '1d466bae0b9b84262b3e7d600d3cf0d0',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786951/green-experience-2/Empresas/IMG_20220607_114323273_dyzkjn.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220609_131022448_wynrw7',
      asset_id: '4f316c9b5f10ef8cc6b4c45d95e48b8c',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786939/green-experience-2/Empresas/IMG_20220609_131022448_wynrw7.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220609_131046326_ays1sw',
      asset_id: '5eb337c16bdf4c48f0ec550fd6037611',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786924/green-experience-2/Empresas/IMG_20220609_131046326_ays1sw.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220716_094934497_myrisf',
      asset_id: '38650adb0819bd05c778b71f9f94dcb2',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786929/green-experience-2/Empresas/IMG_20220716_094934497_myrisf.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220716_105720995_snssnb',
      asset_id: 'cefcfe4813cd6a64e404cae32ee244e6',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786936/green-experience-2/Empresas/IMG_20220716_105720995_snssnb.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220716_105801518_s4nmer',
      asset_id: '95f237312a4f8b8401ec20dc4ce62837',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786936/green-experience-2/Empresas/IMG_20220716_105801518_s4nmer.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220716_105811718_i90yf1',
      asset_id: '44e75c34fb360efdb53582ce892be711',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786928/green-experience-2/Empresas/IMG_20220716_105811718_i90yf1.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220817_132229910_ku44z9',
      asset_id: 'd2e9c0696708ff00911ecea782f6d936',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786929/green-experience-2/Empresas/IMG_20220817_132229910_ku44z9.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220817_132307995_t4vmrs',
      asset_id: '1798d815a107429bd9dcb94fa879b05f',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786931/green-experience-2/Empresas/IMG_20220817_132307995_t4vmrs.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20230124_165258561_rdtojf',
      asset_id: 'e3a909de8c8bcdbd55ae645e27c5ac06',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786935/green-experience-2/Empresas/IMG_20230124_165258561_rdtojf.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20230124_165326969_vcjrs2',
      asset_id: 'b090181e56413b6de20ecf606eb013b6',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786938/green-experience-2/Empresas/IMG_20230124_165326969_vcjrs2.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220815_135800382_zuyf79',
      asset_id: 'c4181389ac72363345eb1d6903c1e7f6',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786924/green-experience-2/Empresas/IMG_20220815_135800382_zuyf79.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220826_105352401_hzqe5z',
      asset_id: '92cce73b5b939fa4fbe8424fba4c9c2e',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786935/green-experience-2/Empresas/IMG_20220826_105352401_hzqe5z.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20221024_142221271_HDR_akmd8s',
      asset_id: '7307faaffff220c6508c9fd58e53e718',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786925/green-experience-2/Empresas/IMG_20221024_142221271_HDR_akmd8s.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20221024_142308741_pcslzx',
      asset_id: 'fc8f273813794d3e6c1f2d21d1f51bd0',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786933/green-experience-2/Empresas/IMG_20221024_142308741_pcslzx.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20221024_142416801_HDR_py4ih8',
      asset_id: 'c3d66a5c827b1209766bde558ea637f8',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786932/green-experience-2/Empresas/IMG_20221024_142416801_HDR_py4ih8.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20230204_124049898_epfqjy',
      asset_id: '9f6ec4b5748aa178402285c9f32a8402',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786927/green-experience-2/Empresas/IMG_20230204_124049898_epfqjy.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220110_143535837_nhclnh',
      asset_id: '0c16fd5b6a69f0b47d32c4981ab245f8',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786944/green-experience-2/Empresas/IMG_20220110_143535837_nhclnh.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220110_143606195_HDR_lv7dwe',
      asset_id: '052fa52b78481c9eb36dcca2a222c0af',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786949/green-experience-2/Empresas/IMG_20220110_143606195_HDR_lv7dwe.jpg'
    },
    {
      public_id: 'green-experience-2/Empresas/IMG_20220110_143733523_HDR_fc1s1u',
      asset_id: '24300fc658ecb3734337751dcd9e2e82',
      folder: 'green-experience-2/Empresas',
      url: 'http://res.cloudinary.com/dt96crg9v/image/upload/v1722786948/green-experience-2/Empresas/IMG_20220110_143733523_HDR_fc1s1u.jpg'
    },
  ],
}