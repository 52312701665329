import React from "react";
import "./Modal.css";

export default function Modal({ selectedImg, setSelectedImg }) {

  const handleClick = (e) => {
    if (!e.target.classList.contains("backdrop-img"))
      setSelectedImg(null);
  };

  return (
    <div className={"backdrop"} onClick={handleClick}>
      <img className={"backdrop-img"} src={selectedImg.url} alt="enlarged pic"/>
    </div>
  )
}