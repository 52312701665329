import React  from "react";
import Carousel from "react-material-ui-carousel";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    position: "relative",
  },
  img: {
    // minWidth: "100%",
    // minHeight: "100%",
    // maxWidth: "150%",
    width: "100%",
    // position: "absolute",
    // top: "0",
    // left: "0",
  },
  text: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    color: "whitesmoke",
    backgroundColor: "gray",
    paddingLeft: "5px",
    // fontSize: "1.4em",
  }
}));

export default function ImageCarousel({ photos }) {
  const classes = useStyles();

  return (
    <Carousel>
      {
        // photos.map( (item, i) => <Photo key={i} item={item} /> )
        photos.map( (photo, i) => (
          <div className={classes.root}>
            <img className={classes.img} key={i} src={photo.link} alt={photo.description} />
            <div className={classes.text}>
              {photo.description}
            </div>
          </div>
        ))
      }
    </Carousel>
  )
}