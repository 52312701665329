import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import React, {useState} from "react";
import Gallery from "../Gallery";
import ImageGrid from "../Gallery/ImageGrid";
import Modal from "../Gallery/Modal";

const useStyles = props => makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  aboutUs: {
    textAlign: "center",
    padding: "2em 0",
    // backgroundColor: "#387002"
    backgroundColor: props.primaryColor
  },
  title: {
    padding: ".5em",
    // color: "whitesmoke"
    color: props.secondaryColor
  },
  paragraph: {
    fontSize: "1.4em",
    // color: "whitesmoke",
    color: props.primaryColor
    // padding: ".5em 2em",
    // paddingLeft: "1em",
    // paddingRight: "1em"
  },
  paper: {
    textAlign: "left",
    padding: ".5em 2em",
    backgroundColor: props.secondaryColor,
  }
}));

export default function GallerySection({ details }) {
  console.log(details.photos.length)
  const classes = useStyles(details.styles)();
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <section className={classes.aboutUs} id={details.tag}>
      <div className={classes.toolbar} />
      <Typography className={classes.title} variant="h2">{details.title}</Typography>
      <Grid container justifyContent={"space-around"}>
        <Grid item xs={11} md={8} lg={8}>
          <Paper className={classes.paper} elevation={4}>
            {details.paragraphs.map((paragraph, index) => (
              <Typography className={classes.paragraph} paragraph key={index}>
                {paragraph}
              </Typography>
            ))}
          </Paper>
        </Grid>
      </Grid>
      <br />

      <div>
        <ImageGrid setSelectedImg={setSelectedImg} photos={details.photos}/>
        {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
      </div>
    </section>
  );
}