import { Fab, makeStyles } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React from "react";

const useStyles = makeStyles((theme) => ({
  fab: {
    left: "2em",
    bottom: "2em",
    position: "fixed",
    backgroundColor: "#25d366",
    color: "#FFF",
    zIndex: "100",
    "&:hover": {
      boxShadow: "2px 2px 3px #999",
    }
  }
}));

export default function WAIcon() {
  const classes = useStyles();

  return (
    <Fab className={classes.fab} color="inherit" href="https://wa.me/5491160582285?text=%C2%A1Hola%21+Como+estas%3F">
      <WhatsAppIcon fontSize="large" />
    </Fab>
  )
}
